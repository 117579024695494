.home {
    width: 100%;
    height: 100vh;
    background: url('../assets/cyber-bg.png') no-repeat center center/cover;
}

.home .container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.home .content {
    height: 75vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 1rem;
    margin-left: 2rem;
    background: rgba(0, 0, 0, 0.1);
}

.home h1 {
    font-size: 3rem;
    color: #f8f8f8;
}

.home a {
    padding-top: 2rem;
    font-size: 2rem;
    color: #f8f8f8;
}
.home .blue {
    color: var(--primary-color);
}

@media screen and (max-width:940px) {
   .home .content {
    margin-left: 1rem;
   } 
}





